<template>
    <div id="app" class="App">
		<div class="App__LayoutContainer">
			<div class="App__MainHeaderContainer">
				<main-header />
			</div>
			<div class="App__MainContentContainer Page__FlexItem">
				<router-view />
				<div class="App__MainFooterContainer">
					<main-footer />
				</div>	
			</div>

		</div>
    </div>
</template>

<script>

import MainHeader from '@/components/Layout/MainHeader.vue'
import MainFooter from '@/components/Layout/MainFooter.vue'

export default 
{
    name: "App",
    components: { MainHeader, MainFooter },

    data()
    {
        return { 

        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>

	.App__LayoutContainer
	{
		display: flex;
		flex-direction: row;

		width: 100%;
		height: 100vh;

		@media (max-width: $media_query_mobile)
		{
			flex-direction: column;
		}

		&>.App__MainHeaderContainer 
		{ 
			height: 100%;
			@media (max-width: $media_query_mobile) { height: unset; }
			// position: absolute;
			// display: none;
			// z-index: 2;
		}
		&>.App__MainContentContainer { height: 100%; overflow-y: scroll; }
	}

</style>