<template>
    <div class="Flags">
        <div class="Flags__Inner Page__TextAlignCenter">
            <h3 class="Page__Heading invert MarginBottom_xlg" style="width: 100%">Customers Worldwide</h3>

            <div class="Page__Flex Gap_xlg AlignMiddle">
                <div class="Flags__FlagContainer Page__Flex AlignMiddle" v-for="flag in data" :key="flag._id">
                    <div class="Flags__Flag noselect" v-bind:style="{ background: 'url(' + flag.url + ')', backgroundSize: 'cover' }"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "Flags",
    components: {},

    data()
    {
        return { 
            data: [
                { _id: 0, url:  require(`@/assets/images/Flags/001-united-kingdom.svg`), name: 'United Kingdom' },
                { _id: 1, url:  require(`@/assets/images/Flags/002-united-states.svg`),  name: 'United States' },
                { _id: 2, url:  require(`@/assets/images/Flags/003-france.svg`),         name: 'France' },
                { _id: 3, url:  require(`@/assets/images/Flags/004-germany.svg`),        name: 'Germany' },
                { _id: 4, url:  require(`@/assets/images/Flags/006-russia.svg`),         name: 'Russia' },
                { _id: 5, url:  require(`@/assets/images/Flags/007-norway.svg`),         name: 'Norway' },
            ]
        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>
    .Flags__Inner
    {
        padding: 30px;
        background: var(--color_background_contrast);

        .Flags__Flag
        {
            height: 80px;
            width: 80px;
            border-radius: 100%;
        }
    }
</style>