<template>
    <div class="AboutUs">
        <div class="Page__Flex">
            <div class="Page__FlexItem Page__Flex Column AlignMiddle">
                <div class="" style="max-width: 80%">
                    <h3 class="Page__Heading MarginBottom_x9">
                        About Us
                    </h3>
                    <p class="Page__Text MarginBottom_x5">
                        We're one of the leading distributors of frozen poultry, in America, 
                        Europe as well as all around the Middle East and Asia; we pride ourselves 
                        in our ability to deliver to every single customer on time and with great care.
                    </p>
                    <p class="Page__Text">
                        Our main suppliers are based in america and follow some of the toughest animal 
                        welfare as well as disease prevention regulations, ensuring the high quality of our products.
                    </p>
                </div>
            </div>
            <div class="Page__FlexItem AboutUs__GridContainer">
                <div class="AboutUs__Grid">
                    <div 
                        class="AboutUs__GridItem AboutUs__Image" 
                        v-bind:style="{ background: 'url(' + image.url + ')', backgroundSize: 'cover' }"
                        v-for="image in images" :key="image.id"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "AboutUs",
    components: {},

    data()
    {
        return { 
            images: [
                { id: 0, url: require(`@/assets/images/AboutUs/img_000.jpg`) },
                { id: 1, url: require(`@/assets/images/AboutUs/img_001.jpg`) },
                { id: 2, url: require(`@/assets/images/AboutUs/img_002.jpg`) },
                { id: 3, url: require(`@/assets/images/AboutUs/img_003.jpg`) }
            ]
        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>
    .AboutUs__Grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    // .div1 { grid-area: 1 / 1 / 2 / 2; }
    // .div2 { grid-area: 1 / 2 / 2 / 3; }
    // .div3 { grid-area: 2 / 2 / 3 / 3; }
    // .div4 { grid-area: 2 / 1 / 3 / 2; }

    .AboutUs__Image
    {
        background-position: center !important;
        height: 300px;
        width: 100%;
    }
</style>