<template>
    <div class="MainHeader">
        <div class="MainHeader__Mobile Page__Flex M_Row AlignMiddle">
            <div class="MainHeader__Logo"><img src="@/assets/images/branding/logo-black-text-300w.png" style="height: 60px" /></div>
            <div class="Page__FlexItem"></div>
            <div class="MainHeader__MobileMenu MarginRight_x5" @click="is_menu_open = true">
                <i class="fa-solid fa-bars"></i> Menu
            </div>
        </div>
        <div class="MainHeader__Inner" v-bind:class="{ 'mobileHidden': is_menu_open === false }">
            <div class="MainHeader__LogoContainer MarginBottom_xlg">
                <div class="MainHeader__Logo"><img src="@/assets/images/branding/logo-black-text-300w.png" /></div>
            </div>

            <div class="MainHeader__Content Page__FlexItem">
                <router-link class="MainHeader__ContentItem MarginBottom_x6" :to="{ name: item.name }" v-for="item in nav_items" :key="item.id" v-on:click.native="is_menu_open = false">
                    <div class="MainHeader__ContentItemText">{{ item.text }}</div>
                </router-link>
                
                <div class="MainHeader__ContentSpacer Page__FlexItem"></div>

                <router-link class="MainHeader__ContentItem" :to="{ name: 'Contact' }">
                    <PageButton isLink="true" :to="{ name: 'Contact' }">Get a Quote Now</PageButton>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "MainHeader",
    components: {},

    data()
    {
        return { 
            nav_items: [
                { id: '0', name: 'home', text: "Home" },
                { id: '1', name: 'Products', text: "Products" },
                // { id: '2', name: 'other', text: "About" },
                // { id: '3', name: 'other', text: "Services" },
                // { id: '4', name: 'other', text: "News" },
                { id: '5', name: 'Contact', text: "Contact" },
            ],

            is_menu_open: false
        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>

    .MainHeader__Mobile { display: none; }
    @media (max-width: $media_query_mobile)
    {
        .mobileHidden { display: none !important; }
        .MainHeader__Mobile { padding: 10px; display: flex; }
    }


    .MainHeader
    {
        height: 100%;

        .MainHeader__Inner
        {
            .MainHeader__Logo>img { width: 100%; }

            @media (max-width: $media_query_mobile)
            {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
                width: unset;
                
                .MainHeader__Logo>img { width: 160px; }
            }

            width: 250px;
            height: 100%;

            background: var(--color_background_secondary);
            box-shadow: 1px 0px 6px 2px #0000003d;
            padding: 35px;

            display: flex;
            flex-direction: column;

            &>.MainHeader__Content
            {
                display: flex;
                flex-direction: column;

                &>.MainHeader__ContentItem
                {
                    text-transform: uppercase;
                    text-decoration: none;

                    &.router-link-exact-active 
                    { 
                        font-weight: bold; 

                        &>.MainHeader__ContentItemText::after { right: 0; }
                        &>.MainHeader__ContentItemText { color: var(--color_text_lighter) }
                    }

                    &>.MainHeader__ContentItemText
                    {
                        display: inline-block;
                        position: relative;

                        font-size: 0.95em;
                        font-weight: 400;

                        &::after 
                        {
                            content: " ";
                            position: absolute;

                            left: 0;
                            right: 100%;
                            bottom: -2px;
                            transition: all 0.2s;

                            border-bottom: 2px solid var(--color_text_lighter);

                        }

                        &:hover { color: var(--color_text_lighter) }
                        &:hover::after { right: 0 !important; }
                    }
                }
            }
        }
    }

</style>