<template>
    <div class="FAQ">

    </div>
</template>

<script>
export default 
{
    name: "FAQ",
    components: {},

    data()
    {
        return { 

        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>

</style>