<template>
	<div class="home">
		<hero backgroundUrl="home_hero.jpg" heading="Peat Springs" subTitle="We supply frozen food to restaurants, shops and wholesalers, worlwide." />
		<about-us />
		<flags />
		<why-choose-us />
		<FAQ />
	</div>
</template>

<script>
	import Hero from '@/components/Layout/Hero.vue'
	import AboutUs from '@/components/ViewComponents/HomeView/AboutUs.vue'
	import Flags from '@/components/ViewComponents/HomeView/Flags.vue'
	import WhyChooseUs from '@/components/ViewComponents/HomeView/WhyChooseUs.vue'
	import FAQ from '@/components/ViewComponents/HomeView/FAQ.vue'
	
	export default 
	{
		name: 'HomeView',
		components: { Hero, AboutUs, Flags, WhyChooseUs, FAQ }
	}
</script>
