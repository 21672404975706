import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/scss/_common.scss"

import PageButton from '@/components/Forms/PageButton'

Vue.component('PageButton', PageButton)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
