<template>
    <div class="WhyChooseUs">
        <div class="WhyChooseUs__Inner">
            <div class="Page__TextAlignCenter">
                <h3 class="Page__Heading"> Why Choose Us</h3>
                <h6 class="Page__Heading MarginBottom_xlg">only the best for our customers</h6>
            </div>

            <div class="Page__Flex row Gap_xlg" style="max-width: 1100px; margin: auto;">
                <div class="WhyChoseUs__Item" v-for="item in data" :key="item._id">
                    <div class="WhyChoseUs__ItemInner">
                        <div class="WhyChoseUs__ItemNumber noselect">
                            <div>{{ item._id + 1 }}</div>
                        </div>
                        <h5 class="Page__Heading WhyChoseUs__ItemHeading MarginBottom_x3">{{ item.name }}</h5>
                        <p class="Page__Text WhyChoseUs__ItemText">{{ item.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "WhyChooseUs",
    components: {},

    data()
    {
        return { 
            data: [
                { _id: 0, name: "Certifications", text: "All of our suppliers follow scrutinious regulations and have international certifications; they also follow dilligent quality assurance procedures, namely ISO, HACCP and many more." },
                { _id: 1, name: "Cost Affective", text: "However small or large your order may be, we will ensure that you can get the best deal for your money - we are able to do this because of an extensive supply chain." },
                { _id: 2, name: "Animal Welfare", text: "We are commited to ensuring high animal welfare and are following the rules established by the world organization for animal health, this also has the added benefit of higher quality product." },
            ]
        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>
    .WhyChooseUs__Inner
    {
        padding: 70px;
    }


    .WhyChoseUs__Item
    {
        padding: 20px;
        padding-top: 70px;
        box-shadow: rgba(151,151,151,.25) 0 3px 11px 0!important;
        border-radius: 2px;

        position: relative;

        .WhyChoseUs__ItemNumber
        {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-30%, -30%);
            background: #eee;
            padding: 40px;
            border-radius: 30% 70% 70% 30% / 26% 30% 70% 74% ;

            &>div
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 35px;
            }
        }
    }
</style>