<template>
    <div>
        <template v-if="isLink">
            <router-link class="PageButton" @click="$emit('click')" :to="to">
                <div class="PageButton__Container">
                    <slot></slot>
                </div>
            </router-link>  
        </template>
        <template v-else>
            <button type="submit" value="Send" class="PageButton" @click="$emit('click')">
                <div class="PageButton__Container">
                    <slot></slot>
                </div>
            </button>  
        </template>
    </div>
</template>


<script>
export default 
{
    name: 'PageButton',
    props: ['isLink', 'to']    
}
</script>


<style lang="scss" scoped>

    .PageButton { text-decoration: none; background: transparent; border: none; display: block; width: 100%; }
    .PageButton__Container
    {
        // background: var(--color_background_tertiary);
        border: 1px solid var(--color_background_tertiary);
        color: var(--color_background_tertiary);
        
        padding: 10px;
        border-radius: 6px;
        text-align: center;
        text-decoration: none;
        font-size: 0.9em;

        cursor: pointer;
        transition: all 0.1s;

        &:hover
        {
            background: var(--color_background_tertiary);
            color: #000;
        }
    }
</style>