<template>
    <div class="MainFooter">
        <div class="MainFooter__Inner Page__Flex Gap_xlg">
            <div class="MainFooter__Column Page__FlexItem">
                <div class="MainHeader__LogoContainer">
                    <div class="MainHeader__Logo"><img src="@/assets/images/branding/Logo-small_final.png" style="height: 70px" /></div>
                    <p class="Page__Text invert MarginTop_x7">+44(0)20 8050 3019</p>
                    <p class="Page__Text invert">info@peatsprings.co.uk</p>
                    <p class="Page__Text invert MarginTop_x3">16 Great Queen Street,<br/>London,<br/>WC2B 5AH,<br/>United Kingdom</p>
                </div>
            </div>
            <div class="MainFooter__Column">
                <h5 class="Page__Heading invert MarginBottom_x7">Pages</h5>
                <router-link class="MainHeader__ContentItem Page__DisplayBlock MarginBottom_x2" :to="{ name: item.name }" v-for="item in nav_items" :key="item.id">
                    <div class="Page__Text invert MainHeader__ContentItemText">{{ item.text }}</div>
                </router-link>
            </div>

            <div class="MainFooter__Column" style="max-width: 200px;">
                <h5 class="Page__Heading invert MarginBottom_x7">About Us</h5>
                <p class="Page__Text invert">Trusted and Reliable Wholesale Poultry Supplier based in London, United Kingdom.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "MainFooter",
    components: {},

    data()
    {
        return { 
            nav_items: [
                { id: '0', name: 'home', text: "Home" },
                { id: '1', name: 'Products', text: "Products" },
                // { id: '2', name: 'other', text: "About" },
                // { id: '3', name: 'other', text: "Services" },
                // { id: '4', name: 'other', text: "News" },
                { id: '5', name: 'Contact', text: "Contact" },
            ]
        }
    },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>
    .MainFooter__Inner
    {
        background: var(--color_background_contrast);
        padding: 70px;
    }
</style>