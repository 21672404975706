<template>
    <div class="Hero" v-bind:style="{ ...(heightOverride && { height: heightOverride }) }">
        <div class="Hero__Inner"
             v-bind:style="{ 
                background: 'radial-gradient(circle at center center, rgb(0 0 0 / 45%) 30%, rgb(0 0 0 / 0%), rgb(0 0 0 / 87%)), url(' + require(`@/assets/images/${this.backgroundUrl}`) + ')', 
                backgroundSize: 'cover'
            }">
            <div class="Hero__Content">
                <hr class="Divider"/>
                <slot></slot>
                <h1 class="Page__Heading MarginBottom_x3">{{ heading || 'Unnamed Page' }}</h1>
                <p class="Page__Text Hero__Text" style="max-width: 450px" v-if="subTitle">{{ subTitle }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "Hero",
    components: {},
    props: ['backgroundUrl', 'heading', 'subTitle', 'heightOverride' ],

    data()
    {
        return { 

        }
    },

    mounted() { },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>
    .Hero
    {
        width: 100%;
        height: 600px;

        .Hero__Inner
        {
            height: 100%; width: 100%;
            position: relative;
            background-position: center !important;

            .Hero__Content
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;

                &, & * *, &>p, &>.Page__Heading
                {
                    color: var(--color_text_contrast) !important;
                }


                .Hero__Text
                {
                    font-size: 1.1em;
                }
            }
        }
    }



    .Divider
    {
        background: linear-gradient(45deg,transparent 10px,currentColor 11px,transparent 12px) 0 0,linear-gradient(-45deg,transparent 10px,currentColor 11px,transparent 12px) 0 0!important;
        height: 15px!important;
        background-repeat: repeat!important;
        background-size: 20px 20px!important;
        min-height: initial!important;
        border-color: currentColor!important;
        border-width: 0!important;
        color: #fff!important;
    }
</style>